import { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import styles from './header.module.scss';
import { useAppSelector } from "../../redux/hooks";
import { AuthService } from "../../shared/services/authService";
import { RolesList } from "../../shared/variables";

const Header = () => {
    const [ isMobileMenuOpen, setIsMobileMenuOpen ] = useState(false);
    const user = useAppSelector(state => state.user);
    const authService = new AuthService();
    const navigate = useNavigate();

    const handleMobileMenuClick = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };
    const handleMobileItemClick = () => {
        setIsMobileMenuOpen(false);
    };

    const handleLogoutItemClick = (event: any) => {
        event.preventDefault();
        authService.logoutRequest();
        navigate('/login');
        setIsMobileMenuOpen(false);
    }

    useEffect(() => {
        if (!user.authenticated) {
            authService.setUserIfIsset();
        }
    }, []);

    return (
        <header className={ styles.header }>
            <NavLink to="/" className={ styles.logoUrl }>
                <div>
                    <span className={ styles.firstWord }>&nbsp;PHARMA</span>
                    <span className={ styles.secondWord }>&nbsp;CONNECT&nbsp;</span>
                </div>
            </NavLink>
            <div className={styles.desktopMenuButton}>
                <ul>
                    <li>
                        <NavLink
                            className={ ( { isActive } ) => (isActive ? "active" : "inactive") }
                            to="/"
                            onClick={ handleMobileItemClick }
                        >
                            Home
                        </NavLink>
                    </li>
                    { user.authenticated && user.role === RolesList.ADMIN  &&
                    <li>
                        <NavLink
                            className={ ( { isActive } ) => (isActive ? "active" : "inactive") }
                            to="/dashboard"
                            onClick={ handleMobileItemClick }
                        >
                            Dashboard
                        </NavLink>
                    </li>
                    }
                    { user.authenticated && user.role === RolesList.ADMIN  &&
                    <li>
                        <NavLink
                            className={ ( { isActive } ) => (isActive ? "active" : "inactive") }
                            to="/create-company"
                            onClick={ handleMobileItemClick }
                        >
                            Create company
                        </NavLink>
                    </li>
                    }
                    { !user.authenticated &&
                        <li>
                            <NavLink
                                className={ ( { isActive } ) => (isActive ? "active" : "inactive") }
                                to="/login"
                                onClick={ handleMobileItemClick }
                            >
                                Login
                            </NavLink>
                        </li>
                    }
                    { !user.authenticated &&
                        <li>
                            <NavLink
                                className={({ isActive }) => (isActive ? "active" : "inactive")}
                                to="/register"
                                onClick={handleMobileItemClick}
                            >
                                Register
                            </NavLink>
                        </li>
                    }
                    { user.authenticated &&
                        <li>
                            <NavLink
                                to="/logout"
                                onClick={handleLogoutItemClick}
                            >
                                Logout
                            </NavLink>
                        </li>
                    }
                </ul>
            </div>
            <button
                className={ `${ styles.mobileMenuButton } ${ isMobileMenuOpen ? styles.open : '' }` }
                onClick={ handleMobileMenuClick }
                aria-label="Mobile Menu"
            >
                <span className={ styles.mobileMenuIcon }></span>
            </button>
            <nav className={ `${ styles.nav } ${ isMobileMenuOpen ? styles.open : styles.close }` }>
                <ul>
                    <li>
                        <NavLink
                            className={ ( { isActive } ) => (isActive ? "active" : "inactive") }
                            to="/"
                            onClick={ handleMobileItemClick }
                        >
                            Home
                        </NavLink>
                    </li>
                    { user.authenticated && user.role === RolesList.ADMIN  &&
                        <li>
                            <NavLink
                                className={ ( { isActive } ) => (isActive ? "active" : "inactive") }
                                to="/dashboard"
                                onClick={ handleMobileItemClick }
                            >
                                Dashboard
                            </NavLink>
                        </li>
                    }
                    { !user.authenticated &&
                        <li>
                            <NavLink
                                className={ ( { isActive } ) => (isActive ? "active" : "inactive") }
                                to="/login"
                                onClick={ handleMobileItemClick }
                            >
                                Login
                            </NavLink>
                        </li>
                    }
                    { !user.authenticated &&
                        <li>
                            <NavLink
                                className={({ isActive }) => (isActive ? "active" : "inactive")}
                                to="/register"
                                onClick={handleMobileItemClick}
                            >
                                Register
                            </NavLink>
                        </li>
                    }
                    { user.authenticated &&
                        <li>
                            <NavLink
                                to="/logout"
                                onClick={handleLogoutItemClick}
                            >
                                Logout
                            </NavLink>
                        </li>
                    }
                </ul>
            </nav>
        </header>
    );
};

export default Header;
