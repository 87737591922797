import { DataTableRow } from "../interfaces/dataTable.interface";
import { FieldUiNames } from "../variables";
import { Company } from "../interfaces/company.interface";

export const customerColumns = () => [
    {
        name: FieldUiNames.name,
        selector: ( row: Company) => row.name,
        sortable: true
    },
    {
        name: FieldUiNames.description,
        selector: ( row: Company) => row.description,
        sortable: true
    },
    {
        name: FieldUiNames.stockTickerSymbol,
        selector: ( row: Company) => row.stockTickerSymbol,
        sortable: true
    },
]

export const adminColumns = () => [
    {
        name: 'ID',
        selector: ( row: Company) => row.id,
    },
    ...customerColumns()
]