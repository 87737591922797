export const apiBaseUrl = 'https://78.140.137.124';
//export const apiBaseUrl = 'http://localhost:3001';

export enum RolesList {
    USER,
    ADMIN,
}

export enum ProtectedRouteTypes {
    authenticatedRoute,
    adminRoute,
    guestRoute
}

export enum FieldUiNames {
    id = 'ID',
    name = 'Company Name',
    description = 'Company Description',
    fullDescription = 'Full Company Description',
    products = 'Product List',
    qualityInformation = 'Product Quality Information',
    partners = 'Partners and Suppliers',
    sales = 'Sales and Revenue',
    profitsAndLooses = 'Profits and Losses',
    tax = 'Tax and Regulatory Information',
    pressArticles = 'Press Articles and Reviews',
    competitorInformation = 'Competitor Information',
    companyExpansion = 'Company Expansion and Growth',
    investors = 'Funding and Investors',
    socialResponsibility = 'Corporate Social Responsibility and Philanthropy',
    history = 'Company History',
    stockTickerSymbol = 'Stock Ticker Symbol',
    contactInformation = 'Contact Information',
    keyPeople = 'Key People',
    structure = 'Company Structure',
    manufacturingFacilities = 'Manufacturing Facilities',
    licenses = 'Certifications and Licenses',
    country = 'Country',
}