import React from 'react';
import { Provider } from "react-redux";
import { store } from "./redux/store";
import AppRouter from "./components/routes/appRouter";

const App: React.FC = () => {
    return (
        <Provider store={ store }>
            <AppRouter></AppRouter>
        </Provider>
    );
};

export default App;
