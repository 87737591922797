import { AxiosService } from "./axiosService";
import { Company } from "../interfaces/company.interface";

export class CompanyService {
    axiosService: AxiosService;
    constructor() {
        this.axiosService = new AxiosService();
    }

    async getCompanyList(): Promise<any> {
        return await this.axiosService
            .get('company')
            .then(result => result.data);
    }

    async getCompanyById(id: string): Promise<any> {
        return await this.axiosService
            .get(`company/${id}`)
            .then(result => result.data);
    }

    async updateCompany(company: Company): Promise<Company> {
        return await this.axiosService
            .put(`company`, company)
            .then(result => result.data);
    }

    async createCompany(company: Company): Promise<number> {
        return await this.axiosService
            .post(`company`, company)
            .then(result => result.data);
    }

    async deleteCompany(id: number): Promise<void> {
        await this.axiosService
            .delete(`company/${id}`);
    }

    async importCompanyList(file: any): Promise<void> {
        const form = new FormData();
        form.append('file', file);
        await this.axiosService
            .post(`company/import`, form, {
                headers: { "Content-Type": "multipart/form-data" },
            });
    }

}