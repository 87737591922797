import React, { useEffect, useState } from 'react';
import { CompanyService } from "../../shared/services/companyService";
import { adminColumns } from "../../shared/configs/dataTable.config";
import { ExpandedComponent } from "../expandedTableItem/expandedTableItem";
import { Company } from "../../shared/interfaces/company.interface";
import DataTable from "react-data-table-component"
import styles from './dashboard.module.scss';

const Dashboard: React.FC = () => {
    const companyService = new CompanyService();
    const [companyList, setCompanyList] = useState<Company[]>([] );
    const [filteredCompanyList, setFilteredCompanyList] = useState<Company[]>([]);
    useEffect(() => {
        async function getCompanies(companyService: CompanyService) {
            const result = await companyService.getCompanyList();
            setCompanyList(result);
            setFilteredCompanyList(result);
        }
        getCompanies(companyService);
    }, []);

    const handleSearchInput = ( event: any ): void => {
        const searchRow = event.target.value;
        if (!searchRow) {
            setFilteredCompanyList(companyList);
        } else {
            const filteredList = companyList.filter(company => {
                return company.name.includes(searchRow)
                    || company.products.some(product => product.name.includes(searchRow) );
            });
            setFilteredCompanyList(filteredList);
        }
    }

    const columns = adminColumns();
    return (
        <div className={styles.dashboardContainer}>
            <div className={styles.searchInputContainer}>
                <input type="text" name="searchText" placeholder="Search by name of company or product name" onChange={handleSearchInput}/>
            </div>
            <DataTable
                columns={columns}
                data={filteredCompanyList}
                defaultSortFieldId={1}
                pagination
                expandableRows
                expandableRowsComponent={ExpandedComponent}
            />
        </div>
    );
};

export default Dashboard;