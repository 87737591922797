import { initialState } from "../actions/userActions";

export default function userReducer ( state = initialState, action: any ) {
    switch (action.type) {
        case 'user/login':
            return {
                ...action.payload,
                authenticated: true
            };
        case 'user/logout':
            return initialState;
        case 'user/loginError':
            return {
                ...initialState,
                loginError: action.payload.error
            };
        case 'user/registrationError':
            return {
                ...initialState,
                registrationError: action.payload.error
            };
        default:
            return state;
    }
}