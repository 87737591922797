import { Navigate } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";
import { ProtectedRouteTypes, RolesList } from "./variables";
// @ts-ignore
const ProtectedRoute = ( { authCase, children }) => {
    const user = useAppSelector(state => state.user);
    switch (authCase) {
        case ProtectedRouteTypes.authenticatedRoute:
            if (user.authenticated) {
                return children;
            }
            break;
        case ProtectedRouteTypes.adminRoute:
            if (user.authenticated && user.role === RolesList.ADMIN) {
                return children;
            }
            break;
        case ProtectedRouteTypes.guestRoute:
            if (!user.authenticated) {
                return children;
            } else {
                return <Navigate to="/" replace />
            }
    }
    return <Navigate to="/login" replace />;
};
export default ProtectedRoute;