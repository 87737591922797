import axios, { AxiosInstance, AxiosRequestConfig, AxiosError } from 'axios';
import { apiBaseUrl } from "../variables";
import { useAppSelector } from "../../redux/hooks";


export class AxiosService {
    private axios: AxiosInstance;
    private user = useAppSelector(state => state.user);

    constructor() {
        this.axios = axios.create({
            baseURL: apiBaseUrl,
        });

        this.axios.interceptors.request.use(
            (config) => {
                if (this.user.token) {
                    config.headers.Authorization = `Bearer ${this.user.token}`;
                }
                return config;
            },
            (error: AxiosError) => {
                return Promise.reject(error);
            }
        );
    }

    public async get(url: string, config?: AxiosRequestConfig) {
        return this.axios.get(url, config);
    }

    public async post(url: string, data?: any, config?: AxiosRequestConfig) {
        return this.axios.post(url, data, config);
    }

    public async put(url: string, data?: any, config?: AxiosRequestConfig) {
        return this.axios.put(url, data, config);
    }

    public async delete(url: string, config?: AxiosRequestConfig) {
        return this.axios.delete(url, config);
    }
}
