import { Product } from "./interfaces/product.interface";

export function getParamFromUrl (path: string) {
    return path.slice(path.lastIndexOf('/') + 1);
}

export function convertStringToProductArray(str: string): Product[] {
    str.trim();
    str = str[0] === ',' ? str.slice(1, str.length) : str;
    str = str[str.length -1] === ',' ? str.slice(0, str.length - 2) : str;
    return str.split(',').map(name => ({
        id: 0,
        name: name,
        createdAt: '',
        updatedAt: ''
    }));
}

export function convertStringToDangerousHtml(str: string): {__html: string} {
    return {__html: str}
}