import React from 'react';
import styles from './import.module.scss';
import { CompanyService } from "../../shared/services/companyService";

const Import: React.FC = () => {
    const companyService = new CompanyService();
    const [importFile, setImportFile] = React.useState(null);
    const handleSubmit = async(event: any) => {
        event.preventDefault()
        await companyService.importCompanyList(importFile);
    }

    const handleFileSelect = (event: any) => {
        setImportFile(event.target.files[0]);
    }
    return (
        <div className={styles.importForm}>
            <h2>Import company list from file</h2>
            <form onSubmit={handleSubmit}>
                <input type="file" onInput={handleFileSelect}/>
                <input type="submit" value="Upload File" />
            </form>
        </div>
    );
};

export default Import;