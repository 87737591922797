import Header from "../header/header";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Homepage from "../homepage/homepage";
import ProtectedRoute from "../../shared/protectRoute";
import { ProtectedRouteTypes } from "../../shared/variables";
import Login from "../login/login";
import Dashboard from "../dashboard/dashboard";
import EditProduct from "../editProduct/editProduct";
import React from "react";
import CreateCompany from "../createCompany/createCompany";
import Register from "../registration/register";
import Import from "../import/import";


const AppRouter: React.FC = () => {
    return (
        <Router>
            <Header/>
            <div>
                <Routes>
                    <Route path="/" element={
                        <Homepage/>
                    }></Route>
                    <Route path="/login" element={
                        <ProtectedRoute authCase={ ProtectedRouteTypes.guestRoute }>
                            <Login/>
                        </ProtectedRoute>
                    }></Route>
                    <Route path="/dashboard" element={
                        <ProtectedRoute authCase={ ProtectedRouteTypes.adminRoute }>
                            <Dashboard/>
                        </ProtectedRoute>
                    }></Route>
                    <Route path="/create-company" element={
                        <ProtectedRoute authCase={ ProtectedRouteTypes.adminRoute }>
                            <CreateCompany/>
                        </ProtectedRoute>
                    }></Route>
                    <Route path="/import" element={
                        <ProtectedRoute authCase={ ProtectedRouteTypes.adminRoute }>
                            <Import/>
                        </ProtectedRoute>
                    }></Route>
                    <Route path="/register" element={
                        <ProtectedRoute authCase={ ProtectedRouteTypes.guestRoute }>
                            <Register/>
                        </ProtectedRoute>
                    }></Route>
                    <Route path="/edit-company/:id" element={
                        <ProtectedRoute authCase={ ProtectedRouteTypes.adminRoute }>
                            <EditProduct/>
                        </ProtectedRoute>
                    }></Route>
                </Routes>
            </div>
        </Router>)
}

export default AppRouter;