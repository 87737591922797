import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from "../store";

// Define a type for the slice state
export interface UserState {
    authenticated: boolean,
    email: string,
    role: number,
    token: string
    loginError: string,
    registrationError: string,
}

// Define the initial state using that type
export const initialState: UserState = {
    authenticated: false,
    email: '',
    role: 0,
    token: '',
    loginError: '',
    registrationError: ''
}
export const userSlice = createSlice({
    name: 'user',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        login: (state, action: PayloadAction<{
            role: number,
            email: string,
            token: string
        }>) => {
            state.authenticated = true;
            state.role = action.payload.role;
            state.email = action.payload.email;
            state.token = action.payload.token;
            state.loginError = '';
            state.registrationError = '';
        },
        loginError: (state, action: PayloadAction<{
            error: string,
        }>) => {
            state.authenticated = false;
            state.loginError = action.payload.error
        },
        registrationError: (state, action: PayloadAction<{
            error: string,
        }>) => {
            state.authenticated = false;
            state.registrationError = action.payload.error
        },
        logout: state => {
            state.email = '';
            state.role = 0;
            state.authenticated = false;
            state.token = '';
        },
    }
})

export const { login, logout, loginError, registrationError } = userSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const getUser = (state: RootState) => state.user
export const getToken = (state: RootState) => state.user.token

export default userSlice.reducer