import React, { useEffect, useState } from 'react';
import { CompanyService } from "../../shared/services/companyService";
import { Company } from "../../shared/interfaces/company.interface";
import { customerColumns } from "../../shared/configs/dataTable.config";
import styles from "../dashboard/dashboard.module.scss";
import DataTable from "react-data-table-component";
import { ExpandedComponent } from "../expandedTableItem/expandedTableItem";

const Homepage: React.FC = () => {
    const companyService = new CompanyService();
    const [companyList, setCompanyList] = useState<Company[]>([] );
    const [filteredCompanyList, setFilteredCompanyList] = useState<Company[]>([]);
    useEffect(() => {
        async function getCompanies(companyService: CompanyService) {
            const result = await companyService.getCompanyList();
            setCompanyList(result);
            setFilteredCompanyList(result);
        }
        getCompanies(companyService);
    }, []);

    const handleSearchInput = ( event: any ): void => {
        const searchRow = event.target.value;
        if (!searchRow) {
            setFilteredCompanyList(companyList);
        } else {
            const filteredList = companyList.filter(company => {
                return company.name.includes(searchRow)
                    || company.products.some(product => product.name.includes(searchRow) );
            });
            setFilteredCompanyList(filteredList);
        }
    }

    const columns = customerColumns();
    return (
        <div className={styles.dashboardContainer}>
            <div className={styles.textContainer}>
                <h1>Uncover the Secrets of Pharmaceutical Industry</h1>
                <p>
                    Here, you can access comprehensive company profiles and detailed information about each pharmaceutical company.
                </p>
                <p>
                    Discover key insights about their history, products, financials, mergers and acquisitions, key personnel, certifications, and more.
                </p>
                <p>
                    Navigate through our catalog effortlessly, using the search bar or browsing categories.
                </p>
                <p>
                    Dive into the world of pharmaceuticals and unlock a wealth of knowledge. Start exploring now and uncover the details you need
                </p>

            </div>
            <div className={styles.searchInputContainer}>
                <input type="text" name="searchText" placeholder="Search by name of company or product name" onChange={handleSearchInput}/>
                <img src="/icons/search.svg" alt="search icon"/>
            </div>
            <div className={styles.tableContainer}>
                <DataTable
                    columns={columns}
                    data={filteredCompanyList}
                    defaultSortFieldId={1}
                    pagination
                    expandableRows
                    expandableRowsComponent={ExpandedComponent}
                    fixedHeader
                />
            </div>
            <div className={styles.textContainer}>
                <p>
                    <b>Contact Us:</b>
                    <p>Email: <a href = "mailto: mail@api-data.com">mail@api-data.com</a></p>
                    <p>Linkedin: <a href="https://www.linkedin.com/in/i-pliner-9a027941/">I. Pliner</a></p>
                </p>
                <br/>
                <p>
                    <b>Disclaimer:</b>
                    <p>The information provided on this website is for general informational purposes only.
                       While we strive to keep the information up to date and accurate, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the website or the information contained on the website.
                       Any reliance you place on such information is therefore strictly at your own risk.</p>
                </p>
                <br/>
                <p>
                    <b>All rights reserved:</b>
                    <p>All content, trademarks, and copyrights are owned by API DATA, a registered company in Israel.</p>
                </p>
            </div>
        </div>
    );
};

export default Homepage;
