import React from 'react';
import { ExpanderComponentProps } from "react-data-table-component";
import { ProductComponent } from "../product/product";
import { useAppSelector } from "../../redux/hooks";
import { FieldUiNames, RolesList } from "../../shared/variables";
import { NavLink } from "react-router-dom";
import { CompanyService } from "../../shared/services/companyService";
import styles from './expandedTableItem.module.scss';
import { Company } from "../../shared/interfaces/company.interface";
import { convertStringToDangerousHtml } from "../../shared/helper";

export const ExpandedComponent: React.FC<ExpanderComponentProps<Company>> = ( { data } ) => {
    const user = useAppSelector(state => state.user);
    const companyService = new CompanyService();
    const deleteCompany = async ( id: number ) => {
        await companyService.deleteCompany(id)
    }
    return (
        <div className={ styles.expandedElement }>
            {
                (user.role === RolesList.ADMIN)
                    ?
                    (<div className={ styles.expandedControls }>
                            <NavLink to={ `/edit-company/${ data.id }` }>EDIT</NavLink>
                            <button onClick={ () => deleteCompany(Number.parseInt(data.id)) }>DELETE</button>
                        </div>
                    )
                    : ''
            }

            <div className={ styles.rowContainer }>
                <div className={ styles.row }>
                    <span className={ styles.rowTitle }>{ FieldUiNames.qualityInformation }:</span>
                    <span className={ styles.rowData }
                          dangerouslySetInnerHTML={ convertStringToDangerousHtml(data.qualityInformation) }></span>
                </div>
                <div className={ styles.row }>
                    <span className={ styles.rowTitle }>{ FieldUiNames.partners }:</span>
                    <span className={ styles.rowData }
                          dangerouslySetInnerHTML={ convertStringToDangerousHtml(data.partners) }></span>
                </div>
                <div className={ styles.row }>
                    <span className={ styles.rowTitle }>{ FieldUiNames.sales }:</span>
                    <span className={ styles.rowData }
                          dangerouslySetInnerHTML={ convertStringToDangerousHtml(data.sales) }></span>
                </div>
                <div className={ styles.row }>
                    <span className={ styles.rowTitle }>{ FieldUiNames.profitsAndLooses }:</span>
                    <span className={ styles.rowData }
                          dangerouslySetInnerHTML={ convertStringToDangerousHtml(data.profitsAndLooses) }></span>
                </div>
                <div className={ styles.row }>
                    <span className={ styles.rowTitle }>{ FieldUiNames.pressArticles }:</span>
                    <span className={ styles.rowData }
                          dangerouslySetInnerHTML={ convertStringToDangerousHtml(data.pressArticles) }></span>
                </div>
                <div className={ styles.row }>
                    <span className={ styles.rowTitle }>{ FieldUiNames.country }:</span>
                    <span className={ styles.rowData }
                          dangerouslySetInnerHTML={ convertStringToDangerousHtml(data.country) }></span>
                </div>
                <div className={ styles.row }>
                    <span className={ styles.rowTitle }>{ FieldUiNames.licenses }:</span>
                    <span className={ styles.rowData }
                          dangerouslySetInnerHTML={ convertStringToDangerousHtml(data.licenses) }></span>
                </div>
                <div className={ styles.row }>
                    <span className={ styles.rowTitle }>{ FieldUiNames.structure }:</span>
                    <span className={ styles.rowData }
                          dangerouslySetInnerHTML={ convertStringToDangerousHtml(data.structure) }></span>
                </div>
                <div className={ styles.row }>
                    <span className={ styles.rowTitle }>{ FieldUiNames.stockTickerSymbol }:</span>
                    <span className={ styles.rowData }
                          dangerouslySetInnerHTML={ convertStringToDangerousHtml(data.stockTickerSymbol) }></span>
                </div>


                <div className={ styles.row }>
                    <span className={ styles.rowTitle }>{ FieldUiNames.manufacturingFacilities }:</span>
                    <span className={ styles.rowData }
                          dangerouslySetInnerHTML={ convertStringToDangerousHtml(data.manufacturingFacilities) }></span>
                </div>
                <div className={ styles.row }>
                    <span className={ styles.rowTitle }>{ FieldUiNames.keyPeople }:</span>
                    <span className={ styles.rowData }
                          dangerouslySetInnerHTML={ convertStringToDangerousHtml(data.keyPeople) }></span>
                </div>
                <div className={ styles.row }>
                    <span className={ styles.rowTitle }>{ FieldUiNames.competitorInformation }:</span>
                    <span className={ styles.rowData }
                          dangerouslySetInnerHTML={ convertStringToDangerousHtml(data.competitorInformation) }></span>
                </div>
                <div className={ styles.row }>
                    <span className={ styles.rowTitle }>{ FieldUiNames.companyExpansion }:</span>
                    <span className={ styles.rowData }
                          dangerouslySetInnerHTML={ convertStringToDangerousHtml(data.companyExpansion) }></span>
                </div>
                <div className={ styles.row }>
                    <span className={ styles.rowTitle }>{ FieldUiNames.investors }:</span>
                    <span className={ styles.rowData }
                          dangerouslySetInnerHTML={ convertStringToDangerousHtml(data.investors) }></span>
                </div>
                <div className={ styles.row }>
                    <span className={ styles.rowTitle }>{ FieldUiNames.socialResponsibility }:</span>
                    <span className={ styles.rowData }
                          dangerouslySetInnerHTML={ convertStringToDangerousHtml(data.socialResponsibility) }></span>
                </div>
                <div className={ styles.row }>
                    <span className={ styles.rowTitle }>{ FieldUiNames.history }:</span>
                    <span className={ styles.rowData }
                          dangerouslySetInnerHTML={ convertStringToDangerousHtml(data.history) }></span>
                </div>
                <div className={ styles.row }>
                    <span className={ styles.rowTitle }>{ FieldUiNames.description }:</span>
                    <span className={ styles.rowData }
                          dangerouslySetInnerHTML={ convertStringToDangerousHtml(data.description) }></span>
                </div>
                <div className={ styles.row }>
                    <span className={ styles.rowTitle }>{ FieldUiNames.contactInformation }:</span>
                    <span className={ styles.rowData }
                          dangerouslySetInnerHTML={ convertStringToDangerousHtml(data.contactInformation) }></span>
                </div>

            </div>
            {
                (data.products.length > 0)
                    ? <ProductComponent productList={ data.products }/>
                    : ''
            }
        </div>
    );
};