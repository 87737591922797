import React from 'react';
import { AuthService } from "../../shared/services/authService";
import { useAppSelector } from "../../redux/hooks";
import styles from './login.module.scss';

const Login: React.FC = () => {
    const authService = new AuthService();
    const user = useAppSelector(state => state.user);
    const handleLoginForm = async ( event: any ): Promise<void> => {
        event.preventDefault();
        await authService.loginRequest(event.target.email.value, event.target.password.value);
    }
    return (
        <div className={styles.loginForm}>
            <h2>Sign in</h2>
            <p className={styles.errorMessage}>{user.loginError}</p>
            <form onSubmit={handleLoginForm}>
                <div>
                    <label htmlFor="email">Email:</label>
                    <input type="email" name="email" placeholder="Enter email" />
                </div>
                <div>
                    <label htmlFor="password">Password:</label>
                    <input type="password" name="password" placeholder="Enter password" />
                </div>
                <button type="submit">Login</button>
            </form>
        </div>
    );
};

export default Login;