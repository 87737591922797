import React, { useState } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import { convertStringToProductArray } from "../../shared/helper";
import { CompanyService } from "../../shared/services/companyService";
import { Company } from "../../shared/interfaces/company.interface";
import { FieldUiNames } from "../../shared/variables";
import styles from './createCompany.module.scss';

const EditProduct: React.FC = () => {
    const companyService = new CompanyService();
    const navigate = useNavigate();
    const [company, setCompany] = useState<Company>({} as Company);
    const [productList, setProductList] = useState<string>('');
    const handleInputChange = (event: any) => {
        const { name, value } = event.target;
        setCompany((prevProps) => {
            return {
                ...prevProps,
                [name]: value
            } as Company;
        });
    };

    const handleProductChange = (event: any) => {
        const { value } = event.target;
        setProductList(value);
        setCompany({
            ...company,
            products: convertStringToProductArray(value)
        });
    };

    const handleSubmit = async ( event: any ) => {
        event.preventDefault();
        const companyId = await companyService.createCompany(company);
        navigate(`/edit-company/${companyId}`);
    };

    return (
        <div>
            <p className={styles.import}>Import company list <NavLink to={'/import'}>IMPORT</NavLink></p>
            <hr/>
            <form onSubmit={handleSubmit} className={styles.editDataForm}>
                <div className="form-control">
                    <label>{FieldUiNames.name}</label>
                    <input
                        type="text"
                        name="name"
                        value={company.name}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-control">
                    <label>{FieldUiNames.description}</label>
                    <input
                        type="text"
                        name="description"
                        value={company.description}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-control">
                    <label>{FieldUiNames.qualityInformation}</label>
                    <input
                        type="text"
                        name="qualityInformation"
                        value={company.qualityInformation}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-control">
                    <label>{FieldUiNames.structure}</label>
                    <input
                        type="text"
                        name="structure"
                        value={company.structure}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-control">
                    <label>{FieldUiNames.partners}</label>
                    <input
                        type="text"
                        name="partners"
                        value={company.partners}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-control">
                    <label>{FieldUiNames.keyPeople}</label>
                    <input
                        type="text"
                        name="keyPeople"
                        value={company.keyPeople}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-control">
                    <label>{FieldUiNames.manufacturingFacilities}</label>
                    <input
                        type="text"
                        name="manufacturingFacilities"
                        value={company.manufacturingFacilities}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-control">
                    <label>{FieldUiNames.licenses}</label>
                    <input
                        type="text"
                        name="licenses"
                        value={company.licenses}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-control">
                    <label>{FieldUiNames.sales}</label>
                    <input
                        type="text"
                        name="sales"
                        value={company.sales}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-control">
                    <label>{FieldUiNames.profitsAndLooses}</label>
                    <input
                        type="text"
                        name="profitsAndLooses"
                        value={company.profitsAndLooses}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-control">
                    <label>{FieldUiNames.pressArticles}</label>
                    <input
                        type="text"
                        name="pressArticles"
                        value={company.pressArticles}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-control">
                    <label>{FieldUiNames.competitorInformation}</label>
                    <input
                        type="text"
                        name="competitorInformation"
                        value={company.competitorInformation}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-control">
                    <label>{FieldUiNames.companyExpansion}</label>
                    <input
                        type="text"
                        name="companyExpansion"
                        value={company.companyExpansion}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-control">
                    <label>{FieldUiNames.investors}</label>
                    <input
                        type="text"
                        name="investors"
                        value={company.investors}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-control">
                    <label>{FieldUiNames.socialResponsibility}</label>
                    <input
                        type="text"
                        name="socialResponsibility"
                        value={company.socialResponsibility}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-control">
                    <label>{FieldUiNames.history}</label>
                    <input
                        type="text"
                        name="history"
                        value={company.history}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-control">
                    <label>{FieldUiNames.stockTickerSymbol}</label>
                    <input
                        type="text"
                        name="stockTickerSymbol"
                        value={company.stockTickerSymbol}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-control">
                    <label>{FieldUiNames.contactInformation}</label>
                    <input
                        type="text"
                        name="contactInformation"
                        value={company.contactInformation}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-control">
                    <label>{FieldUiNames.products}</label>
                    <input
                        type="text"
                        name="products"
                        value={productList}
                        onChange={handleProductChange}
                    />
                </div>
                <div className="form-control">
                    <button type="submit">Create company profile</button>
                </div>
            </form>
        </div>
    );
};

export default EditProduct;
