import React from "react";
import { Product } from "../../shared/interfaces/product.interface";
import styles from './product.module.scss';

export const ProductComponent: React.FC<{ productList: Product[] }> = ({ productList}) => {
    return (
        <div className={styles.productContainer}>
            <span className={styles.productHeader}>Product list:</span>
            <div className={styles.listContainer}>
                {productList.map((product, index) => {
                    const number = ++index;
                    return (
                        <span className={styles.productName} key={number}>{index}: {product.name}</span>
                    )
                })}

            </div>
        </div>
    );
}