import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { login, loginError, logout, registrationError } from "../../redux/actions/userActions";
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";
import { UserTokenData } from "../interfaces/user.interface";
import { AxiosService } from "./axiosService";

export class AuthService {
    axiosService: AxiosService;
    constructor() {
        this.axiosService = new AxiosService();
    }
    user = useAppSelector(state => state.user);
    dispatch = useAppDispatch();

    async loginRequest( email: string, password: string ) {
        await this.axiosService.post('/auth/login', {
            email,
            password
        }).then(result => {
            const token = result.data.accessToken;
            this.dispatchToken(token);
            Cookies.set('userToken', token, {
                expires: 7
            });
        }).catch(error => {
            this.dispatch(loginError({error: error.response.data.message}));
        });
    }

    async registerRequest( email: string, password: string, passwordSubmit: string ) {
        if (passwordSubmit !== password) {
            this.dispatch(registrationError({error: 'Password and confirmation must be the same'}));
            return;
        }
        await this.axiosService.post('/auth/register', {
            email,
            password
        }).then(result => {
            const token = result.data.accessToken;
            this.dispatchToken(token);
            Cookies.set('userToken', token, {
                expires: 7
            });
        }).catch(error => {
            this.dispatch(registrationError({error: error.response.data.message}));
        });
    }

    public logoutRequest = () => {
        Cookies.remove('userToken');
        this.dispatch(logout());
    }

    public setUserIfIsset() {
        const token = Cookies.get('userToken');
        if (token) {
            this.dispatchToken(token);
        }
    }

    private dispatchToken(token: string) {
        const tokenData: UserTokenData = jwtDecode(token);
        this.dispatch(login({
            email: tokenData.email,
            role: tokenData.role,
            token: token,
        }));
    }
}